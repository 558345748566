import {StyleSheet} from 'react-native';

export default
{
    typical:
        StyleSheet.create({
            container:
            {
            
            backgroundColor: '#fff',
            alignItems: 'center',
            height: '100%',
            width: '100%'
            },
        


        
        }),

}