import { Component } from 'react';
import React from 'react';
import { Text, View } from 'react-native';

class Dashboard extends Component<any>
{
    constructor(props:any)
    {
        super(props)
    }

    render()
    {
        return(
            <View>
                <Text>Nothing here yet Ted!</Text>
            </View>
        )
    }
}
export default Dashboard;